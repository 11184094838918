import React from "react";
import "../styles/Portfolio.css";
import CardItem from "./CardItem";
import socialMediaImage from "../assets/socialMediaImage.webp";
import businessCardImage from "../assets/businessCardImage.jpg";
import onlineStoreImage from "../assets/onlineStoreImage.webp";
import newsImage from "../assets/newsImage.jpg";
import crypto from "../assets/crypto.webp";
import ThreeDModelShop from "../assets/3dModelShop.webp";

const Portfolio = () => {

    const projects = [
        {
            id: 1,
            title: "Online Clothing Store",
            description: "MERN Full Stack Website (Landing Page)",
            imageUrl: onlineStoreImage,
            demoUrl: "https://test.crazysoziety.net",
            codeUrl: "https://github.com/itsKiiro/e-commerce_clothing"
        },
        {
            id: 2,
            title: "Crypto Exchange",
            description: "Crypto Exchange with CMC API Calls",
            imageUrl: crypto,
            demoUrl: "https://social.crazysoziety.net",
            codeUrl: "https://github.com/itsKiiro/crypto_"
        },
        {
            id: 3,
            title: "3d Model Online Shop",
            description: "Buy selfmade 3d Models!",
            imageUrl: ThreeDModelShop,
            demoUrl: "https://store.crazysoziety.net",
            codeUrl: "https://github.com/itsKiiro/3d_model_shop"
        },
        {
            id: 4,
            title: "Blog/ Newspaper",
            description: "MERN Full Stack Website",
            imageUrl: newsImage,
            demoUrl: "https://blog.crazysoziety.net",
            codeUrl: "https://github.com/itsKiiro/personal_blog"
        },
        {
            id: 5,
            title: "Social Media App",
            description: "Lost Places/ Secret location finder! (Mobile App)",
            imageUrl: socialMediaImage,
            demoUrl: "",
            codeUrl: "https://github.com/itsKiiro/social_media_app"
        },
        {
            id: 6,
            title: "Business Card Sharing App",
            description: "Share your Business Card with people around you!",
            imageUrl: businessCardImage,
            demoUrl: "",
            codeUrl: "https://github.com/CodecoolGlobal/el-proyecte-grande-4-java-itsKiiro"
        },
    ];


    return (
        <div className="Portfolio" id="portfolio">
            <div className="portfolioHeader">
                <h1 style={{ color: "#fff", borderColor: "#fff" }} className="aboutStart">Portfolio</h1>
            </div>
            <div className="topDivider">
                <h4 style={{ color: "#fff", position: "absolute" }}>Have a look at my recent projects!</h4>
            </div>
            <div className="projectContainer">
                {projects.map((project) => (
                    <CardItem 
                        key={project.id}
                        title={project.title}
                        description={project.description}
                        imageUrl={project.imageUrl}
                        demoUrl={project.demoUrl}
                        codeUrl={project.codeUrl}
                    />
                ))}
            </div>
            <div className="bottomDivider">
                <h4 style={{ color: "#fff", position: "absolute" }}>And many more to come!</h4>
            </div>
        </div>
    )
}

export default Portfolio;